<template>
    <div class="app-calendar overflow-hidden border">

        <b-card no-body>

            <b-card-body>
                <b-row>

                    <b-col cols="12">
                        <b-row class="">

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_task_executor')}}</label>
                                <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.executor_id"
                                        :options="executors"
                                        :multiple="false"
                                        class="w-100"
                                        :placeholder="$t('label_task_executor')"
                                        :reduce="val => val.id"
                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ option.name}}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{$t(option.name)}}</span>
                                    </template>
                                    <template v-slot:no-options="option">
                                        {{$t('label_no_items')}}
                                    </template>
                                </v-select>

                            </b-col>
                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_task_customer')}}</label>
                                <v-select
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.setter_id"
                                        :options="setters"
                                        :multiple="false"
                                        class="w-100"
                                        :placeholder="$t('label_task_customer')"
                                        :reduce="val => val.id"
                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{option.name}}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{option.name}}</span>
                                    </template>
                                    <template v-slot:no-options="option">
                                        {{$t('label_no_items')}}
                                    </template>
                                </v-select>

                            </b-col>
                            <b-col cols="12" md="2" class="mb-1 mt-1">
                                <!--<label>{{$t('label_show_unpaid_payments_on_time')}}</label>-->
                                <b-form-checkbox
                                        v-model="filterData.user_type"
                                        value="C"

                                >
                                    <!--class="mt-1"-->
                                    {{$t('label_show_consultants')}}
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" md="2" class="mb-1 mt-1">
                                <!--<label>{{$t('label_show_unpaid_payments_on_time')}}</label>-->
                                <b-form-checkbox
                                        v-model="filterData.user_type"
                                        value="S"
                                        class="custom-control-success"
                                >
                                    {{$t('label_show_solicitors')}}
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" md="2" class="mb-1 mt-1">
                                <!--<label>{{$t('label_show_unpaid_payments_on_time')}}</label>-->
                                <b-form-checkbox
                                        v-model="filterData.user_type"
                                        value="A"
                                        class="custom-control-warning"
                                >

                                    {{$t('label_show_agents')}}
                                </b-form-checkbox>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <div class="row no-gutters">

            <!-- Calendar -->
            <div class="col position-relative">
                <div class="card shadow-none border-0 mb-0 rounded-0  p-1">
                    <div class="card-body pb-0">
                        <full-calendar
                                ref="refCalendar"
                                :options="calendarOptions"
                                class="full-calendar"
                        />
                    </div>
                </div>
            </div>


        </div>
        <!--{{lang}}-->
    </div>
</template>

<script>
    import {
        BCard, BCardBody, BFormCheckbox
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import FullCalendar from '@fullcalendar/vue'
    import momentTimezonePlugin from '@fullcalendar/moment-timezone'

    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import listPlugin from '@fullcalendar/list'
    import interactionPlugin from '@fullcalendar/interaction'
    import plLocale from '@fullcalendar/core/locales/pl';

    export default {
        components: {
            FullCalendar,
            BCard, BCardBody, vSelect, BFormCheckbox
        },
        data() {
            return {

                fullCalendar: false,

                calendarOptions: {

                    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentTimezonePlugin],
                    timeZone: this.authUser().timezone, //UTC
                    initialView: 'dayGridMonth',
                    headerToolbar: {
                        // start: 'sidebarToggle, prev,next, title', //sidebarToggle - custom btn
                        left: 'prev,today,next',
                        center: 'title',
                        // end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                        end: 'dayGridMonth,timeGridDay,listMonth',
                    },
                    events: this.fetchEvents,

                    editable: true,

                    eventResizableFromStart: true,

                    dragScroll: true,

                    eventStartEditable: false, //disable drag

                    dayMaxEvents: 1,

                    navLinks: true,

                    eventClassNames({ event: calendarEvent }) {
                        // eslint-disable-next-line no-underscore-dangle
                        // const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]
                        //
                        // return [
                        //     // Background Color
                        //     `bg-light-${colorName}`,
                        // ]
                    },
                    eventClick({ event: clickedEvent }) {
                        // * Only grab required field otherwise it goes in infinity loop
                        // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
                        // event.value = grabEventDataFromEventApi(clickedEvent)
                        //
                        // // eslint-disable-next-line no-use-before-define
                        // isEventHandlerSidebarActive.value = true
                    },

                    // customButtons: { // custom btns for toolbar
                    //     sidebarToggle: {
                    //         // --- This dummy text actual icon rendering is handled using SCSS ----- //
                    //         text: 'sidebar',
                    //         click() {
                    //             // eslint-disable-next-line no-use-before-define
                    //             // isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
                    //         },
                    //     },
                    // },

                    dateClick(info) {

                        // event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
                        // // eslint-disable-next-line no-use-before-define
                        // isEventHandlerSidebarActive.value = true
                    },

                    eventDrop({ event: droppedEvent }) {
                        // updateEvent(grabEventDataFromEventApi(droppedEvent))
                    },


                    eventResize({ event: resizedEvent }) {
                        // updateEvent(grabEventDataFromEventApi(resizedEvent))
                    },

                    direction: 'ltr',
                    rerenderDelay: 350,
                    locale: this.$store.state.appConfig.locale,
                    locales: [plLocale],
                   
                },

                filterData: {
                    user_type: [],
                    executor_id: null,
                    setter_id: null,
                },
                executors:[],
                setters:[],
            }
        },
        watch:{
            locale: function(newVal){
                this.fullCalendar.setOption('locale', newVal);
            },
            rtr: function(newVal){
                this.fullCalendar.setOption('locale', newVal);
            },
            filterData: {
                handler: function (old, newVal) {

                    this.fullCalendar.setOption('events', []); //need because of bug
                    this.fullCalendar.setOption('events', this.fetchEvents);
                    // this.fullCalendar.render(); //bug, does not work
                },
                deep: true
            }
        },
        computed:{
            locale: function(){
                return  this.$store.state.appConfig.locale;
            },
            rtr: function(){
                return this.$store.state.appConfig.isRTL ? 'rtl' : 'ltr';
            },
        },


        methods:{

            fetchEvents(info, successCallback){
                if (!info) return;

                this.async('get',  '/calendar', {
                    params: {
                        user_type: this.filterData.user_type,
                        executor_id: this.filterData.executor_id,
                        setter_id: this.filterData.setter_id,
                        start_date: this.$moment(info.startStr).format('DD/MM/YYYY'),
                        end_date: this.$moment(info.endStr).format('DD/MM/YYYY'),

                    }
                }, function (resp) {

                    let prepared = resp.data.map((obj) => {
                        if(obj.event_type == 'contact_meeting'){
                            obj.url = '/contact/tab/meeting/' + obj.contact_meeting.contact.id;
                        } else if(obj.event_type == 'task'){
                            obj.url = '/agreement/task/' + obj.pivot_id;
                        }
                        return obj;
                    });
                    successCallback(prepared);
                });
            },
            getPageData(){
                this.async('get', 'calendar/selects', {}, function(res){
                    this.executors = res.data.executors;
                    this.setters = res.data.setters;
                });
            }
        },
        mounted(){
           let self = this;
           this.$nextTick(function(){
               self.fullCalendar = self.$refs['refCalendar'].getApi();
           });
        },
        created(){
            this.getPageData();
        }

    }
</script>

<style lang="scss">
    @import "@/assets/scss/components/calendar.scss";
</style>
